<template>
  <div>
    <div
      class="min-h-50 d-md-flex w-100 align-items-center justify-content-center"
      v-if="!hasSnapshot && !loading"
    >
      <div class="text-center my-5">
        <b-icon icon="cpu" class="h1" />
        <p class="my-3">
          Ainda não há informações sobre os
          <strong>Sistemas de informação e Gestão</strong> da empresa.
        </p>
        <b-button @click="handleSyncronize()" v-if="is_provider && enabled">
          <b-icon icon="arrow-down-up" class="mr-md-2" />
          <span>Sincronizar agora</span>
        </b-button>
      </div>
    </div>
    <div v-else>
      <div class="d-md-flex align-items-center justify-content-start">
        <small class="mr-2">
          Ultima atualização {{ data.updated_at | dateFull }}
        </small>
        <unicon
          name="redo"
          @click="handleSyncronize()"
          class="pointer small"
          height="16"
          width="16"
        />
      </div>
      <small class="d-block mb-4">
        Os dados de sistemas estão incorretos? altere os no
        <router-link
          target="_blank"
          to="/my-enterprise/system-management/systems"
        >
          no sistemas e Gestão - Sistemas de sua empresa
        </router-link>
      </small>
      <h6 class="mt-4">Sistemas</h6>
      <div class="d-md-flex mt-3 w-100 justify-content-between">
        <b-card
          class="w-100"
          v-for="(system, index) in data.systems"
          :key="index"
          :class="index === data.systems.length - 1 ? 'mr-0' : 'mr-3'"
        >
          <span style="min-height: 40px">{{ system.name }}</span>
          <section
            class="d-md-flex w-100 justify-content-between align-items-center"
          >
            <strong
              class="m-0"
              :class="system.system_type !== 3 ? 'text-success' : 'text-danger'"
            >
              {{
                system.system_type === 2
                  ? "Terceirizado"
                  : system.system_type === 1
                  ? "Próprio"
                  : "Não possui"
              }}
            </strong>
          </section>
          <div v-if="system.system_type !== 3">
            <hr />

            <span v-if="system.system_type === 2">
              Fornecido pela empresa
              <strong
                >{{
                  system.management_systems &&
                    system.management_systems[0] &&
                    system.management_systems[0].enterprise.social_reason
                }}.</strong
              >
            </span>
            <span class="d-block mt-2">
              Representante:
              <strong
                >{{
                  system.management_systems &&
                    system.management_systems[0] &&
                    system.management_systems[0].contact
                }}.</strong
              >
            </span>
          </div>
        </b-card>
      </div>
      <hr />
      <h6 class="mt-4">Gestão</h6>
      <cards-list grid="3">
        <b-card
          class="mb-3"
          v-for="(system, index) in data.management"
          :key="index"
          :class="index === 5 || index === 2 ? 'mr-0' : 'mr-3'"
        >
          <span style="min-height: 40px">{{ system.name }}</span>
          <section
            class="d-md-flex w-100 justify-content-between align-items-center"
          >
            <strong
              class="m-0"
              :class="system.system_type !== 3 ? 'text-success' : 'text-danger'"
            >
              {{
                system.system_type === 2
                  ? "Sim (Terceirizado)"
                  : system.system_type === 1 && !system.has_outsourced
                  ? "Sim"
                  : system.system_type === 1
                  ? "Sim (Próprio)"
                  : "Não"
              }}
            </strong>
          </section>
          <div v-if="system.system_type !== 3">
            <hr />
            <div
              class="mb-3"
              v-for="(managmentSystem, index) in system.management_systems"
              :key="index"
            >
              <strong style="min-height: 40px">{{
                managmentSystem.name
              }}</strong>
              <br />
              <span v-if="system.system_type === 2">
                Fornecido pela empresa
                <strong
                  >{{
                    managmentSystem.enterprise &&
                      managmentSystem.enterprise.social_reason
                  }}.</strong
                >
              </span>
              <span class="d-block mt-2">
                Representante:
                <strong
                  >{{ managmentSystem && managmentSystem.contact }}.</strong
                >
              </span>
            </div>
          </div>
        </b-card>
      </cards-list>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      hasSnapshot: false,
      enabled: false,
      isEmpty: false,
      loading: false,
      data: {},
      service_id: "",
      provider_id: "",
    };
  },

  computed: {
    ...mapGetters(["is_preview", "user", "preview_personify", "is_provider"]),
  },
  methods: {
    ...mapActions(["get_snapshot", "set_snapshot", "show_loading"]),
    handleClickChange() {
      this.invertValue = !this.invertValue;
    },
    getSnapshot() {
      this.loading = true;
      this.show_loading({
        loading: true,
      });
      this.get_snapshot({
        type: "system-management",
        service_id: this.service_id,
        provider_id: this.provider_id,
      }).then((response) => {
        this.enabled = response.enabled;
        if (!response.updated_at) {
          this.hasSnapshot = false;
          this.loading = false;
          return;
        } else {
          this.hasSnapshot = true;
          this.data = response.data;
          this.data.updated_at = response.updated_at;
        }
        this.show_loading({
          loading: false,
        });
        this.loading = false;
      });
    },
    handleSyncronize() {
      this.$bvModal.show("modal-generating-snapshot");
      this.set_snapshot({
        type: "system-management",
        service_id: this.service_id,
        provider_id: this.provider_id,
      }).then(() => {
        this.$bvModal.hide("modal-generating-snapshot");
        this.hasSnapshot = true;
        this.getSnapshot();
      });
    },
  },
  mounted() {
    this.service_id = this.$route.params.service_id;
    this.provider_id = this.$route.params.provider_id;
    this.getSnapshot();
  },
};
</script>
